import {Button, Card, Link, CardHeader} from "@nextui-org/react";
import {FaChevronLeft, FaChevronRight} from "react-icons/fa6";
import {useState} from "react";
import Swipe from "react-easy-swipe";

import { HOMEPAGE_DATA_TYPE } from "@/components/helpers/constant";
import SharedService from "@/services/shared-service";

var _ = require('lodash');

/**
 *
 * @param {Object} parable
 * @returns {JSX.Element}
 */
function ParableContent(parable) {
    return (
        <>
            <div className="text-center font-semibold text-white">
                <p>{parable.data.authorName}</p>
            </div>
            <Link href={SharedService.getPostPath(parable.data)} className="block">
                <div className="text-white text-justify sm:text-center mt-5 mx-5">
                    <span className="inline-block" dangerouslySetInnerHTML={{__html: parable.data.metaDescription}}></span>
                </div>
                <div className="text-secondary text-center my-10">
                <span className="inline-block text-xl">
                    {parable.data.quotation} - {parable.data.title}
                </span>
                </div>
            </Link>
        </>
    );
}

export default function Parables(parablesData) {
    const parables = parablesData.data;
    const [currentSlide, setCurrentSlide] = useState(0);

    // Actions Slide
    const handleNextSlide = () => {
        let newSlide = currentSlide === parables.value.length - 1 ? 0 : currentSlide + 1;
        setCurrentSlide(newSlide);
    };
    const handlePrevSlide = () => {
        let newSlide = currentSlide === 0 ? parables.value.length - 1 : currentSlide - 1;
        setCurrentSlide(newSlide);
    };

    return (
        <div>
            <Card className="rounded-[5px] bg-gray-800/50 !bg-cover !bg-center !bg-[url('/webp/book.webp')] !bg-blend-darken">
                <CardHeader className="justify-center">
                    <div className="flex justify-between items-center">
                        <Button className="ml-5 md:ml-0 bg-primary" radius="full" isIconOnly size="sm"
                                aria-label="Previous" onClick={handlePrevSlide}>
                            <FaChevronLeft className="fill-white h-[32px] w-[32px] p-1"/>
                        </Button>

                        <div className="mx-10 text-2xl font-bold">
                            <Link href={`/chuyen-de/${HOMEPAGE_DATA_TYPE.PARABLES.link}`} className="md:mx-10 text-2xl font-bold text-white whitespace-nowrap">
                                {HOMEPAGE_DATA_TYPE.PARABLES.name.toLocaleUpperCase()}
                            </Link>
                        </div>
                        <Button className="mr-5 md:mr-0 bg-primary" radius="full" isIconOnly size="sm" aria-label="Next"
                                onClick={handleNextSlide}>
                            <FaChevronRight className="fill-white h-[32px] w-[32px] p-1"/>
                        </Button>
                    </div>
                </CardHeader>

                <Swipe className=""
                       onSwipeLeft={handleNextSlide}
                       onSwipeRight={handlePrevSlide}
                >
                    {parables.total > 0 && parables.value.map((parable, index) => {
                            if (index === currentSlide) {
                                return (
                                    <div key={index}>
                                        <ParableContent data={parable}/>
                                    </div>
                                );
                            }
                        }
                    )}
                </Swipe>
            </Card>
        </div>
    )
}